@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap');
body[data-v-37039f54] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
*[data-v-37039f54] {
    box-sizing: border-box;
}
*[data-v-37039f54],[data-v-37039f54]:hover {
    outline: 0;
}
body[data-v-37039f54] {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
body[data-v-37039f54] {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    color: #000;
    color: var(--site-color);
}
html[data-v-37039f54] {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
[data-v-37039f54]:root {
    --theme1-bg: #3ccfd7;
    --theme2-bg: #3ccfd7;
}
[data-v-37039f54]:root {
    --primary-bg: #fff;
    --site-color: #000;
}
.login-wrapper[data-v-37039f54] {
    background-image: linear-gradient(#3ccfd7, #3ccfd7);
    background-image: linear-gradient(var(--theme1-bg), var(--theme2-bg));
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}
*[data-v-37039f54],[data-v-37039f54]:after,[data-v-37039f54]:before {
    box-sizing: border-box;
}
*[data-v-37039f54] {
    outline: 0;
}
.mb-3[data-v-37039f54] {
    margin-bottom: 1rem !important;
}
.text-center[data-v-37039f54] {
    text-align: center !important;
}
.logo-login[data-v-37039f54] {
    display: block;
    widows: 100%;
}
.login-form[data-v-37039f54] {
    background-color: #fff;
    background-color: var(--primary-bg);
    border-radius: 8px;
    margin: 0 auto;
    max-width: 300px;
    width: 100%;
    padding: 26px 16px 16px 16px;
}
.loginInner1.authentication[data-v-37039f54] {
    width: 100%;
}
img[data-v-37039f54] {
    vertical-align: middle;
    border-style: none;
}
.logo-login img[data-v-37039f54] {
    max-height: 65px;
    max-width: 250px;
}
.loginInner1.authentication .featured-box-login[data-v-37039f54] {
    padding: 0;
}
h4[data-v-37039f54] {
    margin-top: 0;
    margin-bottom: .5rem;
}
h4[data-v-37039f54] {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h4[data-v-37039f54] {
    font-size: 1.5rem;
}
.login-form h4[data-v-37039f54] {
    color: #3ccfd7;
    color: var(--theme2-bg);
}
.mt-3[data-v-37039f54] {
    margin-top: 1rem !important;
}
a[data-v-37039f54] {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
a[data-v-37039f54] {
    text-decoration: none;
}
a[data-v-37039f54]:hover {
    color: #0056b3;
    text-decoration: underline;
}
a[data-v-37039f54],
a[data-v-37039f54]:hover,
a[data-v-37039f54]:active {
    text-decoration: none;
}
input[data-v-37039f54] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input[data-v-37039f54] {
    overflow: visible;
}
.otp-input[data-v-37039f54] {
    width: 35px;
    height: 40px;
    padding: 4px;
    margin: 0 4px;
    font-size: 24px;
    border-radius: 4px;
    border: 1px solid red !important;
    text-align: center;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-Rjo7S.style-Rjo7S[data-v-37039f54] {
    display: flex;
    flex-direction: row;
}
#style-NWE8h.style-NWE8h[data-v-37039f54] {
    display: flex;
}
#style-yZwpn.style-yZwpn[data-v-37039f54] {
    display: flex;
    align-items: center;
}
#style-EyBMr.style-EyBMr[data-v-37039f54] {
    display: flex;
    align-items: center;
}
#style-vtjPW.style-vtjPW[data-v-37039f54] {
    display: flex;
    align-items: center;
}
#style-9WODC.style-9WODC[data-v-37039f54] {
    display: flex;
    align-items: center;
}
#style-gBsQN.style-gBsQN[data-v-37039f54] {
    display: flex;
    align-items: center;
}
#style-yPy5i.style-yPy5i[data-v-37039f54] {
    display: flex;
    align-items: center;
}